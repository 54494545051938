<template> 
      <div class="inner11">
        <p class="title">
          <span>进度详情</span>
          <span @click="close" class="pointer">
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <p class="sel">
            <span class="spaa"><span class="star">* </span>名称</span>
            <span>{{ form.name }}</span>
          </p>
          <p class="sel">
            <span class="spaa"><span class="star">* </span>项目区域</span>
            <span>{{ form.areaName }}</span>
          </p>
          <p class="sel">
            <span class="spaa"><span class="star">* </span>负责人</span>
            <span>{{ form.leadName }} · {{ form.leadPhone }}</span>
          </p>
          <p class="sel">
            <span class="spaa"><span class="star">* </span>申请人</span>
            <span>{{ form.userName}} · {{ form.department  }}</span>
          </p>
          <!-- <p class="sel">
            <span class="spaa"><span class="star">* </span>部门</span>
            <span>{{ form.department }}</span>
          </p> -->
          <div class="manyitem">
            <div class="title1">
              <p>回款进度</p>
            </div>
            <div class="item">
              <el-table :data="tableData1">
                <el-table-column prop="sdatatime" label="回款日期">
                </el-table-column>
                <el-table-column
                  label="回款金额（元）"
                  prop="amount"
                >
                </el-table-column>
                <el-table-column label="回款状态" >
                   <template slot-scope="scope">
              <span v-if="scope.row.state == 3">驳回</span>
              <span v-if="scope.row.state == 1">已到账</span>
              <span v-if="scope.row.state == 2">待审核</span>
            </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <p class="sel">
            <span class="spa">合同总额</span>
            <span>{{ form.repaymentTotal }}</span>
          </p>
          <el-progress
            :text-inside="true"
            :stroke-width="16"
            :percentage="form.repaymentRate"
          ></el-progress>
          <div class="twoitem" style="margin-top: 14px">
            <div>
              <span class="spaa"><span class="star">* </span>累计回款</span
              ><span class="blue">￥{{ form.repaymentPaid }}</span>
            </div>

            <div>
              <span class="spa_phone"><span class="star">* </span>应收账款</span
              ><span class="red">￥{{ form.repaymentTotal }}</span>
            </div>
          </div>
          <div class="manyitem">
            <div class="title1">
              <p>开票进度</p>
            </div>
            <div class="item">
              <el-table :data="tableData2">
                <el-table-column label="开票日期" prop="createTime">
                </el-table-column>
                <el-table-column
                  label="开票金额（元）"
                  prop="amount"
                >
                </el-table-column>
                <el-table-column label="开票状态" prop="schedule">
                  <template slot-scope="scope">
              <span v-if="scope.row.state == 3">驳回</span>
              <span v-if="scope.row.state == 4">已作废</span>
              <span v-if="scope.row.state == 1">已开票</span>
              <span v-if="scope.row.state == 2">待开票</span>
            </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <p class="sel">
            <span class="spa">合同总额</span>
            <span>{{ form.total }}</span>
          </p>
          <el-progress
            :text-inside="true"
            :stroke-width="16"
            :percentage="form.invoiceRate"
          ></el-progress>
          <div class="twoitem" style="margin-top: 14px">
            <div>
              <span class="spaa"><span class="star">* </span>累计开票</span
              ><span class="blue">￥{{ form.invoicePaid }}</span>
            </div>

            <div>
              <span class="spa_phone"><span class="star">* </span>剩余票面</span
              ><span class="red">￥{{ form.invoiceTotal}}</span>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import {
 getProgressInfo
} from "@/api/apis.js";
export default {
  data() {
    return {
      //地图实例
      form:{},
      formFile:[],
      fileList:[],
      tableData1:[],
      tableData2:[],
      srcList:[],
      option3:[],
      zfForm:{},
      option4: [{
          value: 1,
          label: '现金支付'
        }, {
          value: 2,
          label: '电子汇款'
        },{
          value: 3,
          label: '对公账户'
        }],
    };
  },
  props: ["pddata"],
  methods: {
      close(){
        this.$emit("close")
        
        this.form={}
      },

  },
  mounted(){   
     let  data={id:this.pddata.id,type:this.pddata.type}    
        getProgressInfo(data).then(res=>{
            if(res.code==200){
          this.form=res.data
          this.tableData1=res.data.repayment||[]
          this.tableData2=res.data.invoice||[]
        }else{
          this.$message.error(res.data.msg);
        }
        })
  },
  
  beforeDestroy(){
    this.form={}
    this.zfForm={}
  }
};
</script>

<style lang="less">
.inner11 {
      width: 456px;
      max-height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        flex: 1;
        padding: 0 34px;
        box-sizing: border-box;
        padding-top: 20px;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .sel {
          display: flex;
          margin-bottom: 14px;
          line-height: 26px;
          .el-input__inner {
            width: 200px;
          }
          .el-select .el-input {
            width: 200px;
          }
        }
        .blue {
          color: #0090ff !important;
          font-weight: bold;
        }
        .red {
          color: #ff2825 !important;
          font-weight: bold;
        }
        .manyitem {
          color: #000;
          margin-bottom: 14px;

          .title1 {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            font-size: 14px;
            p {
              font-weight: bold;
              margin-bottom: 10px;
            }
          }
        }
        .el-table th {
          background-color: white;
          padding: 0px;
        }
        .twoitem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
        }
        .spaa {
          display: inline-block;
          width: 83px;
          margin-left: -9px;
          color: rgba(0, 0, 0);
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        .spa {
          display: inline-block;
          width: 75px;
          color: rgba(0, 0, 0);
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 348px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-input {
            width: 270px;
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 348px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        height: 60px;
        display: flex;
        justify-content: center;
        & > button:nth-child(1) {
          margin-right: 20px;
        }
      }
    }
</style>