<template>
  <div class="AC_manage">
    <div class="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条数据吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
        <p class="search">
        <el-input
        v-if="permissoin.includes('query')"
          type="text" 
          size="mini"
          clearable
          v-model="searchForm.name"
          placeholder=" 请输入关键字"
        ></el-input>
        <button class="btn1" v-if="permissoin.includes('query')" @click="currentPage2=1;getData()">
          <i class="el-icon-search"></i>
          查询
        </button>
        <button class="btn2" v-if="permissoin.includes('add')" @click="modal=1;modalState=0">
          <i class="el-icon-plus"></i>
          新增
        </button>
      </p>
      <div class="table srcollstyle">
           <el-table :data="tableData" style="width:98%" border max-height='780px'>
            <el-table-column type="index" label="序号" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" width="280" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="abbreviation" label="简称">
            </el-table-column>
            <el-table-column prop="person" label="联系人" >
            </el-table-column>
            <el-table-column prop="phone" label="联系电话" >
            </el-table-column>
            <el-table-column prop="address" label="联系地址" :show-overflow-tooltip="true"
              width="300">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-edit" 
                      v-if="permissoin.includes('edit')"
                      :command="{ num: 2, data: scope.row }"
                      >编辑</el-dropdown-item>
                    <el-dropdown-item
                    icon="el-icon-delete" 
                    v-if="permissoin.includes('del')"
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item>
                    <el-dropdown-item
                    icon="el-icon-d-arrow-right"
                    v-if="scope.row.type == 2&&permissoin.includes('paymentCollection')"
                    :command="{ num: 5, data: scope.row }"
                    >回款进度</el-dropdown-item
                  >
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          
          </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="currentPage2"
          @size-change="handleSizeChange"
          prev-text="上一页"
          next-text="下一页"
          :page-size="pageSize"
          :page-sizes="[10,20,50,100]"
          layout="total,sizes, prev, pager, next, jumper "
          :total="total"
        >
        </el-pagination>
    </div>
    </div>
    <div class="modal" v-show="modal==1">
        <div class="inner">
          <p class="title">
          <span v-if="modalState==0">新增</span>
          <span v-if="modalState==1">修改</span>
          <span @click="modal = 0;form={}" class="pointer">
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
               <div class="net">
                      <el-form
            :model="form"
            :rules="rules" status-icon
            ref="ruleForm"
            label-width="80px"
            label-position="left"
          >
            <el-form-item label="名称:" prop="name">
              <el-input v-model="form.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="简称:" prop="abbreviation">
              <el-input v-model="form.abbreviation" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="联系人:" prop="person">
              <el-input v-model="form.person" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="电话:" prop="phone">
              <el-input v-model="form.phone" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="联系地址:" prop="address">
              <el-input v-model="form.address"  placeholder="请输入邮寄地址" size="mini"></el-input>
            </el-form-item>
          </el-form>
                  
            </div>
        </div>
        <div class="bottom">
            <button class="btn_red" @click="onsubmit">保存</button>
            <button class="btn_gray" @click="modal = 0;form = {}">取消</button>
        </div>
          </div>
    </div>
    <div class="modal" v-if="modal == 5">
        <PD :pddata='pddata' @close='close'></PD>
    </div>
  </div>
</template>

<script>
import { merchantGetList,deleteMerchant,saveMerchant
} from '@/api/apis.js'
import { timestampToTime } from "@/utils/getTime.js";
import PD from "@/components/Progress_details.vue";
export default {
  data() {
    return {
      form: {},
      info: 1,
      pddata:{},
      modalState:0,
      timestampToTime,
      currentPage2:1,
      dialogVisible:false,
      pageSize:10,
      hide:1,
      keysArr:[],
      option1: [{
          value: 2,
          label: '代理商'
        }, {
          value: 1,
          label: '供应商'
        }],
      modal:0,
      total:0,
      permissoin:[],
      tableData:[],
      searchForm:{},
       rules: {
        name: [{ required: true, message: "请输入商户名称", trigger: "blur" }],
        person: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        phone: [{ required: true, message: "请输入电话", trigger: "blur" }],
        address: [{ required: true, message: "请输入联系地址", trigger: "blur" }],
        abbreviation: [{ required: true, message: "请输入商户简称", trigger: "blur" }],

      },
        props: {
        label: "name",
        children: "children",
      },
    };
  },
  components: {
    PD
  },
  methods: {
    getData(){
      let data={
        name:this.searchForm.name,
        pageSize:this.pageSize,
        type:2,
        pageNum:this.currentPage2,
      }
      merchantGetList(data).then(res=>{
        if(res.code==200){
        this.tableData=res.data.list
        this.total=res.data.total-0

      }else{
        this.tableData=[]    
        this.total=0 
      }
      })},
      handleCurrentChange(v){this.currentPage2=v
      this.getData()},
    handleSizeChange(v){
      this.pageSize=v
      this.getData()
    },
    close(){
        this.modal = 0
    },
    handleCommand(d) {
      this.form.id=d.data.id
      if(d.num==3){
        this.dialogVisible=true      
      }else if(d.num==2){
        this.modal=1
        this.modalState=1
        this.form=JSON.parse(JSON.stringify(d.data))
      }else if(d.num==5){
        this.pddata={id:d.data.id,type:2}
        this.modal=5
      }
    },
    deletePro(){
      let data ={id:this.form.id}
        deleteMerchant(data).then(res=>{
          if(res.code==200){
            this.$message({
              type:'success',
              message:res.msg
            })
            this.dialogVisible=false
            this.getData()
          }else{
           this.$message.error(res.data.msg)
          }
        })
    },
    onsubmit() {
      
      if(this.modalState==0){
        let data = {};

        data = {
          name: this.form.name,
          type: 2,
          abbreviation: this.form.abbreviation,
          person: this.form.person,
          phone: this.form.phone,
          address: this.form.address,

        };
        
         this.$refs.ruleForm.validate((valid) => {
          if (valid) {
             saveMerchant(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.getData()
          this.modal = 0;
          this.form = {};
        } else {
          this.$message.error(res.data.msg);
        }
      });
          } else {
            return false;
          }
        });
      }else if(this.modalState==1){
        let data = {};
        data = {
          name: this.form.name,
          abbreviation: this.form.abbreviation,
          person: this.form.person,
          phone: this.form.phone,
          address: this.form.address,
          id:this.form.id,
        };
      this.$refs.ruleForm.validate((valid) => {
          if (valid) {
             saveMerchant(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.getData()
          this.modal = 0;
          this.form = {};
        } else {
          this.$message.error(res.data.msg);
        }
      });
          } else {
            return false;
          }
        });
      }
     
    },
  },
  mounted() {
    //获取权限
    this.permissoin=[]
    let arr = JSON.parse(localStorage.getItem('perArr'))
    let routePath =this.$route.path
    arr.forEach(item=>{
      if(item.purl==routePath){  
            this.permissoin.push(item.permission)        
      }
    })
    this.getData()
  },
};
</script>

<style lang="less" >
.AC_manage {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main{
    flex: 1;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    box-sizing: border-box;
    .search{
      margin: 22px 0;
      .el-input{
        width: 208px;
      }
    }
    .table{
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide{
      position: absolute;
      top:48%;
      left: 0px;
      cursor: pointer;
    }
  }
   .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 456px;
      max-height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px  #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center{
        flex: 1;
        padding-left: 34px;
        box-sizing: border-box;
        .el-button--mini, .el-button--mini.is-round{
          padding:  6px 12px;
          margin-left: 4px;
        }
          .text_item{
            margin-bottom: 12px;
            &>span:nth-child(1){
              color: #F56C6C;
              margin-right: 2px;
            }
            &>span:nth-child(2){
              display: inline-block;
              margin-left: 198px;
              cursor: pointer;
              color: #d62829;
              i{
                font-weight: 600;
                font-size: 14px;
              }
            }
          }
          .net{
            margin-top: 20px;
            .line{
              border-top: 1px dashed rgb(221, 214, 214);
              width: 348px;
            }
            p{
              margin-bottom: 20px;
            }
            .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input{
          width: 270px;
        }
        .el-input-number{
          .el-input{
          width: 130px;
        }
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
          }
          
          .el-form{
            .el-form-item__label{
              font-size: 12px;
              color: #222328;
            }
            .el-form-item{
              margin-bottom: 10px;
            }
            .el-input{
              width: 270px;
            }
            .el-form-item__error{
              padding-top: 0px;
            }
          }
        
      }
      .bottom{
        height: 60px;
        display: flex;
        justify-content: center;
        &>button:nth-child(1){
          margin-right: 20px;
        }
      }
    }
   }
}
</style>